import React from 'react'
import Footer from '../components/Sections/Footer'
import Blog from '../components/Sections/Blog'
import Navigations from '../components/Nav/navigations'

export default function News() {
  return (
    <>
      <Navigations />
        <Blog/>
      <Footer />
    </>
  )
}
