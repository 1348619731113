import React from "react";
import Footer from "../components/Sections/Footer";
import vidSocialImpact1 from "../assets/video/vidsocialimpact1.mp4";
import vidSocialImpact2 from "../assets/video/vidsocialimpact2.mp4";
import FotoSocialImpact from "../assets/img/socialimpact.jpeg";
import FotoSocialImpact2 from "../assets/img/socialimpact2.jpeg";
import FotoSocialImpact3 from "../assets/img/socialimpact3.jpeg";
import FotoSocialImpact4 from "../assets/img/socialimpact4.jpeg";
import FotoSocialImpact5 from "../assets/img/socialimpact5.jpeg";
import FotoSocialImpact6 from "../assets/img/socialimpact6.jpeg";
import FotoSocialImpact7 from "../assets/img/socialimpact7.jpeg";
import Navigations from "../components/Nav/navigations";
import FMD1 from "../assets/img/fmd1.jpeg";
import FMD2 from "../assets/img/fmd2.jpeg";
import FMD3 from "../assets/img/fmd3.jpeg";
export default function SocialImpact() {
  return (
    <>
      <Navigations />
      <div className="lightBg">
        <div className="container">
          <div style={{ paddingTop: 40, paddingBottom: 60 }}>
            <div style={{ lineHeight: 2 }}>
              <h1
                className=" marginTitle textCenter fontRobotto mb10 font40"
              >
                {" "}
                Welcome to <span className="semiBold">#samarata </span>{" "}
              </h1>

              <div
                className="row textCenter fontCabin justifyCenter"
              >
                <div className="font20 lh30">
                  <span className="semiBold">#samarata </span> participates in social impact to ensure that workers who collaborate with us are paid fairly and equally. We do not encourage people to buy products at discounts to appreciate that workers get decent wages because our products are handmade
                </div>
              </div>
            </div>
            <div style={{ marginTop: 50 }}>
              <div className="row textCenter">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <video
                    width="70%"
                    height="auto"
                    controls
                    // autoPlay={true}
                    playsInline={true}
                    // loop={true}
                  >
                    <source src={vidSocialImpact1} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <img width="70%" height="auto" alt="socialImpact" src={FotoSocialImpact}></img>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <video
                    width="70%"
                    height="auto"
                    controls
                    // autoPlay={true}
                    playsInline={true}
                    // loop={true}
                  >
                    <source src={vidSocialImpact2} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>

          <div style={{ paddingTop: 20, paddingBottom: 60 }}>
            <div style={{ lineHeight: 2 }}>
              <h1
                className="textCenter fontRobotto mb10 font40"
              >
                Bumi Biru X Djaman Doeloe
              </h1>

              <div
                className="row textCenter mb50 font20 lh30 fontCabin justifyCenter"
              >
                <div className="">
                We involve in{" "}
                  <span className="extraBold"> Jelajah Nusantara</span> collaboration programe by promoting Bhumibiru Shibori (the Rosita Batik Shibori sub brand), to introduce Indonesian fabrics and fashion which use the concept of Sustainable Fashion and Zero Waste. Djaman Doeloe's commitment to sustainability and environmental impact continues in Bhumibiru Shibori's collaboration program with Djaman Doeloe (Mariot Bonvoy) in a movement called Sungai Watch, a program for cleaning and protecting rivers in Bali. 
                </div>
              </div>

              <div className="row textCenter" style={{ marginTop: 20 }}>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <img
                    style={{ objectFit: "cover" }}
                    width="100%"
                    height={450}
                    alt="socialImpact"
                    src={FMD2}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <img
                    style={{ objectFit: "cover" }}
                    width="100%"
                    height={450}
                    alt="socialImpact"
                    src={FMD1}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <img
                    style={{ objectFit: "cover" }}
                    width="100%"
                    height={450}
                    alt="socialImpact"
                    src={FMD3}
                  />
                </div>
              </div>
              <div
                className="row textCenter"
                style={{
                  fontFamily: "Cabin",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <div className="textCenter mb50 textCenter font20 lh30 fontCabin justifyCenter">
                  <br />
                  Through this movement, they tested various trash barriers which are useful for preventing plastic waste from flowing into the sea. Apart from that, Bhumi Biru Shibori also collaborates with the Westin Hotel to manage used bedsheets to be reused into shoe bags and tote bags.
                </div>
              </div>
            </div>
          </div>

          <div style={{ paddingTop: 10, paddingBottom: 60 }}>
            <div style={{ lineHeight: 2 }}>
              <h1
                className="textCenter fontRobotto mb10 font40 mb20"
              >
                Rosita Batik shibori X FMD Management
              </h1>

              <div
                className="row textCenter font20 lh30 fontCabin justifyCenter"
              >
                <div>
                We collaborate with FMDManagement for a special modeling class for disabilities to develop children's skills so that they have experience in fashion, by providing skills in making shibori with natural indigo dye.
                </div>
              </div>

              <div className="row textCenter" style={{ marginTop: 20 }}>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <img
                    style={{ objectFit: "cover" }}
                    width="100%"
                    height={250}
                    alt="socialImpact"
                    src={FotoSocialImpact2}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <img
                    style={{ objectFit: "cover" }}
                    width="100%"
                    height={250}
                    alt="socialImpact"
                    src={FotoSocialImpact3}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <img
                    style={{ objectFit: "cover" }}
                    width="100%"
                    height={250}
                    alt="socialImpact"
                    src={FotoSocialImpact4}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{ paddingTop: 20, paddingBottom: 60 }}>
            <div style={{ lineHeight: 2 }}>
              <h1
                className="textCenter fontRobotto mb10 font40 mb20"
                >
                Rosita Batik Shibori X SMK Rodatul Huda
              </h1>

              <div className="row textCenter" style={{ marginTop: 20 }}>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <img
                    style={{ objectFit: "cover" }}
                    width="100%"
                    height={250}
                    alt="socialImpact"
                    src={FotoSocialImpact5}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <img
                    style={{ objectFit: "cover" }}
                    width="100%"
                    height={250}
                    alt="socialImpact"
                    src={FotoSocialImpact6}
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <img
                    style={{ objectFit: "cover" }}
                    width="100%"
                    height={250}
                    alt="socialImpact"
                    src={FotoSocialImpact7}
                  />
                </div>
              </div>

              <div
                className="row textCenter font20 lh30 fontCabin justifyCenter"
              >
                <div>
                  <br />
                  Collaborating with Rodatul Huda Vocational School teachers on how to make shibori, which will then be practiced with their students. We hope that the knowledge we convey can be useful and have a good impact on students.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
