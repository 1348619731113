import React from 'react';
import Footer from '../components/Sections/Footer';
import '../components/CardProduct/cardProduct.css';
import Navigations from '../components/Nav/navigations';
import returnrepair1 from "../assets/img/returnrepair1.jpeg";
import returnrepair3 from "../assets/img/returnrepair3.jpg";

import "./sus.css";
const returnContent = [
  {
    key: 1,
    value: [
      `Returns can only be made if the item is damaged or the size is not suitable`
    ]
  },
  {
    key: 2,
    value: [
      `Product information will be returned to us no later than 2 days after the goods are received`
    ]
  },
  {
    key: 3,
    value: [
      `Please send information via WhatsApp number`,
      `(+62 821-2503-5550/+62 822‑4180‑6880)`
    ]
  },
  {
    key: 4,
    value: [
      `Goods must be returned in new condition, and in good packaging`
    ]
  },
  {
    key: 5,
    value: [
      `We do not accept returns of damaged or dirty items`,
      `(we will send back damaged or dirty items)`
    ]
  },
  {
    key: 6,
    value: [
      `All shipping costs are borne by the consumer`,
    ]
  },
]

const ripairContent = [
  {
    key: 1,
    value: [
      `We provide clothing repairs (oversized clothes/makeovers)`,
      `if you buy our products in custom sizes`
    ]
  },
  {
    key: 2,
    value: [
      `Please send information via WhatsApp number`,
      '+62 821-2503-5550 / +62 822‑4180‑6880',
    ]
  },
  {
    key: 3,
    value: [
      'All shipping costs are borne by the consumer'
    ]
  }
]


export default function ReturnRepair() {

  return (
    <>
      <Navigations />
      <div className="container">
        <div className='mt100'>
          <div>
            <div
              className="font20 fontRobotto lh30"
            >
              <p>We believe that comfort in using our products, appropriate size, is the main thing so that our products can be used continuously, last a long time and create confidence, for this reason we provide returns and repairs for our products.</p>
            </div>
            <p className="font25 fontRobotto mt50 mb20 textCenter semiBold">How to return or repair</p>

            <div className="row" style={{ marginBottom: 25, marginTop: 35 }}>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flexCenter">
                <div style={{ maxWidth: "100%", }}>
                  <p className="font25 fontRobotto mt25 mb20 semiBold">Return Policy</p>
                  {returnContent.map((item) => (
                    <div className="flex font20 fontRobotto lh30 mb25">
                      <p>{item?.key}.</p>
                      <div>
                        {item?.value.map((val) => (
                          <p className="" style={{ marginLeft: 10 }}>
                            {val}
                          </p>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <img
                  style={{ objectFit: "cover" }}
                  width="100%"
                  height={'auto'}
                  alt="return"
                  src={returnrepair1}
                />
              </div>
            </div>

            <div className="row" style={{ paddingBottom: 25 }}>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <img
                  style={{ objectFit: "cover" }}
                  width="100%"
                  height={'auto'}
                  alt="repair"
                  src={returnrepair3}
                />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flexCenter">
                <div style={{ maxWidth: "100%", }}>
                  <p className="font25 fontRobotto mt25 mb20 semiBold">Repair Policy</p>
                  {ripairContent.map((item) => (
                    <div className="flex font20 fontRobotto lh30 mb25">
                      <p>{item?.key}.</p>
                      <div>
                        {item?.value.map((val) => (
                          <>
                            <p className="" style={{ marginLeft: 10 }}>
                              {val}
                            </p>
                          </>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div
              className="font20 fontRobotto lh30 mt20 mb50"
            >
              <p>Please remember that our products are made by quality crafters and before delivery we always ensure the products are in the best condition.</p>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
