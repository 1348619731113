const initialState = {
  dataWomen: null,
  dataSearch: null,
  dataMen: null,
  dataAccessories: null,
  loading: false,
};

const Product = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'PRODUCT_WOMEN_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'PRODUCT_WOMEN_SUCCESS':
      return {
        ...state,
        loading: false,
        dataWomen: action.payload
      };
    case 'PRODUCT_WOMEN_ERROR':
      return {
        ...state,
        loading: false,
        dataWomen:null,
        error: action.payload
      };
      case 'PRODUCT_WOMEN_SEARCH_REQUEST':
        return {
          ...state,
          loading: true,
        };
      case 'PRODUCT_WOMEN_SEARCH_SUCCESS':
        return {
          ...state,
          loading: false,
          dataWomenSearch: action.payload
        };
      case 'PRODUCT_WOMEN_SEARCH_ERROR':
        return {
          ...state,
          loading: false,
          dataWomenSearch:null,
          error: action.payload
        };
      case 'PRODUCT_MEN_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'PRODUCT_MEN_SUCCESS':
      return {
        ...state,
        loading: false,
        dataMen: action.payload
      };
    case 'PRODUCT_MEN_ERROR':
      return {
        ...state,
        loading: false,
        dataMen:null,
        error: action.payload
      };
      case 'PRODUCT_ACCESSORIES_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'PRODUCT_ACCESSORIES_SUCCESS':
      return {
        ...state,
        loading: false,
        dataAccessories: action.payload
      };
    case 'PRODUCT_ACCESSORIES_ERROR':
      return {
        ...state,
        loading: false,
        dataAccessories:null,
        error: action.payload
      };
      case 'SEARCH_PRODUCT':
        return { 
          ...state, 
          loading: false, 
          dataSearch: action.payload, 
        };
    default:
      return state
  }
};
export default Product;