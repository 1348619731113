import React from 'react'
import Footer from '../components/Sections/Footer'
import Navigations from '../components/Nav/navigations'
import styled from 'styled-components'
import contact from '../assets/img/contact.jpg'
import { Link } from 'react-router-dom'

export default function Contact() {

  return (
    <>
      <Navigations />
      <Wrapper>
      <div className="whiteBg">
        
          <HeaderInfo>
          <h3 className="marginTitle textCenter fontRobotto mb10 font40">We will be happy if you contact us directly</h3>
          </HeaderInfo>
          <ContentWrapper>
            <div>
            <div className=''>
              <h4 className='font25 lineH35 fontRobotto darkColor'>Our Contact </h4>
              <p className='font20 lineH35 fontCabin'>(admin 1) +62 821-2503-5550</p>
              <p className='font20 lineH35 fontCabin'>(admin 2) +62 822‑4180‑6880</p>
              <p className='font20 lineH35 fontCabin'>rositabatikshibori@gmail.com</p>
            </div>

            <div className='mt50'>
              <h4 className='font25 lineH35 fontRobotto darkColor'>Office and Boutique</h4>
              <p className='font20 lineH35 fontCabin'>Northwest Boulevard NV1 no 55 </p>
              <p className='font20 lineH35 fontCabin'>Pakal Citraland Utara 60192</p>
              <p className='font20 lineH35 fontCabin'>Jawa Timur, Indonesia</p>
            </div>

            <div className='mt50'>
              <h4 className='font25 lineH35 fontRobotto darkColor'>Social Media</h4>
              <Link to='https://www.instagram.com/rositabatikshibori.official?igsh=MTRqZTNlYTEyeGdzNA=='><p className='font20 lineH35 fontCabin' style={{cursor: 'pointer'}}>Rositabatikshibori.official</p></Link>
              <Link to='https://www.instagram.com/bhumibiru.official?igsh=d2puZ2M1anljeHQ2'><p className='font20 lineH35 fontCabin' style={{cursor: 'pointer'}}>Bhumibiru Shibori</p></Link>
            </div>
            </div>
              <img className='img-contact' src={contact} alt="contact" />
            
          </ContentWrapper>
        
      </div>
    </Wrapper>
      <Footer />
    </>
  )
}


const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 30px auto;
  @media (max-width: 860px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;


