import React, { useEffect, useMemo } from 'react';
import Footer from '../components/Sections/Footer';
import '../components/CardProduct/cardProduct.css';
import CardProduct from '../components/CardProduct/cardProduct'
import { useDispatch, useSelector } from 'react-redux';
import { GetproductWomen, numberWithCommas } from '../redux/actions/product';
import Navigations from '../components/Nav/navigations';


export default function ProductWomen() {
  const dispatch = useDispatch();

  const { dataWomen, dataSearch } = useSelector((s) => s.Product);

  useEffect(() => {
    dispatch(GetproductWomen())
  }, [dispatch])

  const filterSearch = useMemo(() =>
    dataWomen?.filter((val) => val.product_label === dataSearch?.payload),
    [dataSearch?.payload, dataWomen])

  return (
    <>
      <Navigations />
      <div className="container">
        <div className='mt100'>
          <h3 className="font40 extraBold textCenter fontRobotto">Fashion Women</h3>
          {filterSearch?.length > 0 ? (
            <div className="subcontainer">
            <div className="grid">
              {filterSearch?.map((item, index) => (
                <CardProduct
                  key={index}
                  image={`https://api.rositabatikshibori.com/asset/product/${item?.product_photo}`}
                  title={item.product_name}
                  price={numberWithCommas(item.product_price)}
                />
              ))}
            </div>
          </div>
          ) : (
            <p className='fontEmptyData font20 fontCabin'>Sorry, there are no products in this collection</p>
          )}
        </div>
      </div>
      <Footer />
    </>
  )
}
