import React from "react";
// Sections
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Footer from "../components/Sections/Footer"
import Navigantions from "../components/Nav/navigations";

export default function Landing() {
  return (
    <>
      {/* <Navbar /> */}
      <Navigantions/>
      <Header />
      <Services />
      <Projects />
      <Blog />
      {/* <Pricing /> */}
      {/* <Contact /> */}
      <Footer />
    </>
  );
}


