import React from "react";
import Footer from "../components/Sections/Footer";
import Sustainability3 from "../assets/img/sustainability3.jpeg";
import Sustainability6 from "../assets/img/sustainability6.jpeg";
import Sustainability8 from "../assets/img/sustainability8.jpeg";
import Sustainability13 from "../assets/img/sustainability13.jpeg";
import Sustainability14 from "../assets/img/sustainability14.jpeg";
import Sustainability15 from "../assets/img/sustainability15.jpeg";
import Navigations from "../components/Nav/navigations";
import "./sus.css";


export default function SocialImpact() {
  return (
    <>
      <Navigations />
      <div id="port">
        <div id="name">
          <p>Sustainability</p>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <div>
            <div
              className="row subContent font20 textCenter"
            >
              <div>
                <p>Our products focus on sustainability, by minimizing waste and pollution, and make our products last a long time.</p>
              </div>
            </div>

            <div className="row textCenter" style={{ marginBottom: 25 }}>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <img
                  style={{ objectFit: "cover" }}
                  width="100%"
                  height={'auto'}
                  alt="sustainability8"
                  src={Sustainability8}
                />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <img
                  style={{ objectFit: "cover" }}
                  width="100%"
                  height={'auto'}
                  alt="sustainability3"
                  src={Sustainability3}
                />
              </div>
            </div>

            <div className="row" style={{ marginBottom: 25 }}>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <img
                  style={{ objectFit: "cover" }}
                  width="100%"
                  height={'auto'}
                  alt="sustainability13"
                  src={Sustainability13}
                />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flexCenter">
                <div style={{ maxWidth: "100%", }}>
                  <p className="contentLine1 font20">
                    We use natural Indigo dye which is safe for the environment because it does not contain dangerous chemical.
                  </p>
                  <p className="contentLine2 font20">
                    The fabric is selected from natural fibers and does not contain polyester to avoid environmental damage.
                  </p>
                </div>
              </div>
              </div>

            <div className="row" style={{ paddingBottom: 25 }}>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flexCenter">
                <div style={{ maxWidth: "100%", }}>
                  <p className="contentLine1 font20">
                  And although the production of shibori takes a long time and is complicated, we do it with love and passion.
                  </p>
                  <p className="contentLine2 font20">
                  We hope our love for art can go along with our great desire to help other people, at least to improve the welfare of women around our production house.
                  </p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <img
                  style={{ objectFit: "cover" }}
                  width="100%"
                  height={'auto'}
                  alt="sustainability6"
                  src={Sustainability6}
                />
              </div>
            </div>

            <div className="row textCenter" style={{ paddingBottom: 100 }}>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <img
                  style={{ objectFit: "cover" }}
                  width="100%"
                  height={'auto'}
                  alt="sustainability14"
                  src={Sustainability14}
                />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <img
                  style={{ objectFit: "cover" }}
                  width="100%"
                  height={'auto'}
                  alt="sustainability15"
                  src={Sustainability15}
                />
              </div>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
