import React, { useState, useEffect, useRef, useCallback } from 'react';
import './navigations.css';
import accessories from '../../assets/img/accessories.JPG';
import women from '../../assets/img/baju2.jpg';
import men from '../../assets/img/men.jpeg';
import { searchProduct } from '../../redux/actions/product';
import { useDispatch } from 'react-redux';
import logobatikshibori from '../../assets/img/logobatikshibori.png'


const Navigations = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  },[dropdownRef]);

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpen, handleClickOutside]);

  return (
    <nav className="navbar">
      <div className="logo">
        <img src={logobatikshibori} alt="Logo" />
      </div>
      <button className="hamburger" onClick={toggleMenu}>
        &#9776;
      </button>
      <ul className={`nav-list ${isMenuOpen ? 'open' : ''}`}>
        <li className="nav-item">
          <a href="/" className="nav-link font20 fontRobotto nav-sub-item">We For Ecosystem</a>
        </li>
        <li className="nav-item dropdown" ref={dropdownRef}>
  <div style={{color: '#27456F'}} className="nav-link font20 fontRobotto nav-sub-item" onClick={toggleDropdown}>
            Product
          </div>
          {isDropdownOpen && (
            <div className="dropdown-content">
              <button className="close-button" onClick={closeDropdown}>×</button>
              <div className="dropdown-section">
                <img src={women} alt="Women Category" className="submenu-image" />
                <div>
                  <h4 className='font20 fontRobotto'>Women</h4>
                  <ul>
                    <li><a href="/product_women" onClick={() => dispatch(searchProduct({ payload: 'Tops and Blouses' }))}>Tops and Blouses</a></li>
                    <li><a href="/product_women" onClick={() => dispatch(searchProduct({ payload: 'Dresses' }))}>Dresses</a></li>
                    <li><a href="/product_women" onClick={() => dispatch(searchProduct({ payload: 'Pants and Skirt' }))}>Pants and Skirt</a></li>
                    <li><a href="/product_women" onClick={() => dispatch(searchProduct({ payload: 'Outerwear' }))}>Outerwear</a></li>
                    <li><a href="/product_women" onClick={() => dispatch(searchProduct({ payload: 'Shawls' }))}>Shawls</a></li>
                    <li><a href="/product_women" onClick={() => dispatch(searchProduct({ payload: 'Fabric' }))}>Fabric</a></li>
                  </ul>
                </div>
              </div>
              <div className="dropdown-section">
                <img src={men} alt="Men Category" className="submenu-image" />
                <div>
                  <h4 className='font20 fontRobotto'>Men</h4>
                  <ul>
                    <li><a href="/product_men" onClick={() => dispatch(searchProduct({ payload: "Men's Shirts" }))}>Men's Shirts</a></li>
                    <li><a href="/product_men" onClick={() => dispatch(searchProduct({ payload: "Pants and Sarung" }))}>Pants and Sarung</a></li>
                    <li><a href="/product_men" onClick={() => dispatch(searchProduct({ payload: "Outerwear" }))}>Outerwear</a></li>
                  </ul>
                </div>
              </div>
              <div className="dropdown-section">
                <img src={accessories} alt="Accessories Category" className="submenu-image" />
                <div>
                  <h4 className='font20 fontRobotto'>Accessories</h4>
                  <ul>
                    <li><a href="/product_accessories" onClick={() => dispatch(searchProduct({ payload: "Clutch & Purse" }))}>Clutch & Purse</a></li>
                    <li><a href="/product_accessories" onClick={() => dispatch(searchProduct({ payload: "Tote Bag" }))}>Tote Bag</a></li>
                    <li><a href="/product_accessories" onClick={() => dispatch(searchProduct({ payload: "Laptop Bag" }))}>Laptop Bag</a></li>
                    <li><a href="/product_accessories" onClick={() => dispatch(searchProduct({ payload: "Scrunchies" }))}>Scrunchies</a></li>
                    <li><a href="/product_accessories" onClick={() => dispatch(searchProduct({ payload: "Key Chain" }))}>Key Chain</a></li>
                    <li><a href="/product_accessories" onClick={() => dispatch(searchProduct({ payload: "Footwear" }))}>Footwear</a></li>
                    <li><a href="/product_accessories" onClick={() => dispatch(searchProduct({ payload: "Jewelries" }))}>Jewelries</a></li>
                    <li><a href="/product_accessories" onClick={() => dispatch(searchProduct({ payload: "Home Living" }))}>Home Living</a></li>
                    <li><a href="/product_accessories" onClick={() => dispatch(searchProduct({ payload: "Pillow Case" }))}>Pillow Case</a></li>
                    <li><a href="/product_accessories" onClick={() => dispatch(searchProduct({ payload: "Table Runner" }))}>Table Runner</a></li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </li>
        <li className="nav-item">
          <div className="nav-link font20 fontRobotto nav-sub-item">Our Value</div>
          <div className="dropdown">
            <div className="dropdown-item">
              <a href="/social_impact">Social Impact</a>
            </div>
            <div className="dropdown-item">
              <a href="/sustainability">Sustainability</a>
            </div>
          </div>
        </li>
        <li className="nav-item">
          <a href="/news" className="nav-link font20 fontRobotto nav-sub-item">News</a>
        </li>
        <li className="nav-item">
          <a href="/contact" className="nav-link font20 fontRobotto nav-sub-item">Contact</a>
        </li>
      </ul>
    </nav>
  );
};

export default Navigations;
