import React from "react";
import styled from "styled-components";

export default function BlogBox({ tag, title, text, action, author, photo }) {
  return (
    <WrapperBtn
      className="animate pointer"
      onClick={action ? () => action() : null}
    >
      <Wrapper className="whiteBg shadow">
        <img
          src={photo}
          width={'100%'}
          height={200}
          style={{ objectFit:'cover' }}
          alt="addddd"
        />
        <h3
          className="font15 bold"
          style={{ padding: 10, textAlign: "center", fontFamily:'Roboto' }}
        >
          {title.substring(0, 60) + "..."}
        </h3>
        <p className="font13" style={{ padding: 10, textAlign: "center", fontFamily:'Cabin' }}>
          {text}
        </p>
        {/* <p className="font13 extraBold">{author}</p> */}
        {/* <div className="flex">
          <p className="tag coralBg radius6 font13 extraBold">{tag}</p>
        </div> */}
      </Wrapper>
    </WrapperBtn>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  // padding: 20px 30px;
  margin-top: 30px;
`;
const WrapperBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  :hover {
    opacity: 0.5;
  }
`;
