import React from "react";
import { Link } from "react-scroll";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import styled from "styled-components";
import bg1 from "../../assets/img/background1.jpeg";
import bg2 from "../../assets/img/background2.jpeg";
import bg3 from "../../assets/img/background3.jpeg";
import bg4 from "../../assets/img/background4.jpeg";
// import './style.css'

export default function Header() {
  const images = [bg4, bg1, bg3, bg2];
  return (
    <>
      <Wrapper id="home" className="center mt100">
        <Slide>
          <div className="each-slide-effect">
            <div style={{ backgroundImage: `url(${images[0]})` }}>
              <span
                style={{
                  color: "#27456F",
                  fontSize: 20,
                  lineHeight: 1.5,
                  fontFamily: "Cabin",
                  fontWeight: "400",
                }}
              >
                <h1
                  style={{
                    fontWeight: "900",
                    fontSize: 40,
                    fontFamily: "Roboto",
                  }}
                >
                  IKAT.TEKAN.CELUP
                </h1>
                <br />
                We make unique fashion using Shibori techniques and <br />{" "}
                natural dyes that focus on sustainable fashion. <br />
                <Link
                  to="services"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={1000}
                >
                  <button
                    style={{
                      marginTop: 50,
                      backgroundColor: "#27456F",
                      // marginInline: 100,
                      textAlign: "center",
                      borderRadius: 6,
                      padding: 14,
                      color: "white",
                      fontSize: 16,
                      fontFamily: "Cabin",
                    }}
                  >
                    Know More
                  </button>
                </Link>
              </span>
            </div>
          </div>

          <div className="each-slide-effect2">
            <div style={{ backgroundImage: `url(${images[1]})` }}>
              <span
                style={{
                  color: "white",
                  fontSize: 20,
                  lineHeight: 1.5,
                  fontFamily: "Cabin",
                  fontWeight: "400",
                }}
              >
                <h1
                  style={{
                    fontWeight: "900",
                    fontSize: 40,
                    fontFamily: "Roboto",
                  }}
                >
                  UPCYCLE PRODUCT
                </h1>
                <br />
                We collect the left over fabric and use them into useful items <br /> such as totebags, clutches, key chains, coin purses, hats, sandals, etc, <br />
                with the aim of minimizing waste. <br />
                <a href="/product_accessories">
                  <button
                    style={{
                      marginTop: 50,
                      backgroundColor: "#27456F",
                      // marginInline: 100,
                      textAlign: "center",
                      borderRadius: 6,
                      padding: 14,
                      color: "white",
                      fontSize: 16,
                      fontFamily: "Cabin",
                    }}
                  >
                    Show Product
                  </button>
                </a>
              </span>
            </div>
          </div>

          <div className="each-slide-effect">
            <div style={{ backgroundImage: `url(${images[2]})` }}></div>
          </div>

          <div className="each-slide-effect">
            <div style={{ backgroundImage: `url(${images[3]})` }}></div>
          </div>
        </Slide>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
  min-height: 780px;
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 240px;
  }
`;
// const LeftSide = styled.div`
//   width: 50%;
//   height: 100%;
//   @media (max-width: 960px) {
//     width: 100%;
//     order: 2;
//     margin: 50px 0;
//     text-align: center;
//   }
//   @media (max-width: 560px) {
//     margin: 80px 0 50px 0;
//   }
// `;
// const RightSide = styled.div`
//   width: 50%;
//   height: 100%;
//   @media (max-width: 960px) {
//     width: 100%;
//     order: 1;
//     margin-top: 30px;
//   }
// `;
// const HeaderP = styled.div`
//   max-width: 470px;
//   padding: 15px 0 50px 0;
//   line-height: 1.5rem;
//   @media (max-width: 960px) {
//     padding: 15px 0 50px 0;
//     text-align: center;
//     max-width: 100%;
//   }
// `;
// const BtnWrapper = styled.div`
//   max-width: 190px;
//   @media (max-width: 960px) {
//     margin: 0 auto;
//   }
// `;
// const GreyDiv = styled.div`
//   width: 30%;
//   height: 700px;
//   position: absolute;
//   top: 0;
//   right: 0;
//   z-index: 0;
//   @media (max-width: 960px) {
//     display: none;
//   }
// `;
// const ImageWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   position: relative;
//   width: 100%;
//   padding-top: 100px;
//   @media (max-width: 960px) {
//     width: 100%;
//     justify-content: center;
//   }
// `;
// const Img = styled.img`
//   @media (max-width: 560px) {
//     width: 80%;
//     height: auto;
//   }
// `;
// const QuoteWrapper = styled.div`
//   position: absolute;
//   left: 0;
//   bottom: 50px;
//   max-width: 330px;
//   padding: 30px;
//   z-index: 99;
//   @media (max-width: 960px) {
//     left: 20px;
//   }
//   @media (max-width: 560px) {
//     bottom: -50px;
//   }
// `;
// const QuotesWrapper = styled.div`
//   position: absolute;
//   left: -20px;
//   top: -10px;
// `;
// const DotsWrapper = styled.div`
//   position: absolute;
//   right: -100px;
//   bottom: 100px;
//   z-index: 2;
//   @media (max-width: 960px) {
//     right: 100px;
//   }
//   @media (max-width: 560px) {
//     display: none;
//   }
// `;

// const Intro = styled.div`
//   display: table;
//   width: 100%;
//   padding: 0;
//   background: url(../../assets/img/batik.jpg) center center no-repeat;
//   background-color: #e5e5e5;
//   -webkit-background-size: cover;
//   -moz-background-size: cover;
//   background-size: cover;
//   -o-background-size: cover;
// `;

// .intro {
//   display: table;
//   width: 100%;
//   padding: 0;
//   background: url(../assets/img/logobatikshiboribg.png) center center no-repeat;
//   background-color: #e5e5e5;
//   -webkit-background-size: cover;
//   -moz-background-size: cover;
//   background-size: cover;
//   -o-background-size: cover;
// }
// .intro .overlay {
//   background: rgba(0, 0, 0, 0.2);
// }
// .intro h1 {
//   font-family: "Raleway", sans-serif;
//   color: #fff;
//   font-size: 82px;
//   font-weight: 700;
//   text-transform: uppercase;
//   margin-top: 0;
//   margin-bottom: 10px;
// }
// .intro h1 span {
//   font-weight: 800;
//   color: #5ca9fb;
// }
// .intro p {
//   color: #fff;
//   font-size: 22px;
//   font-weight: 300;
//   line-height: 30px;
//   margin: 0 auto;
//   margin-bottom: 60px;
// }
// header .intro-text {
//   padding-top: 350px;
//   padding-bottom: 200px;
//   text-align: center;
// }
