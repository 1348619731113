import React from "react";
import Navigations from "../components/Nav/navigations";
import Footer from "../components/Sections/Footer";
import faqimage from "../assets/img/faq.jpg";
export default function FAQ() {
  return (
    <>
      <Navigations />
      <div className="">
        <div className="container">
          <div style={{ marginBottom: 60 }}>
            <div className="row subContent textCenter">
              <img
                className=""
                src={faqimage}
                alt="faqimage"
                style={{ width: "60%" }}
              />
            </div>

            <div class="faq-content" style={{marginTop: -100}}>
              <div class="faq-question">
                <input id="q1" type="checkbox" class="panel" />
                <div class="plus">+</div>
                <label for="q1" class="panel-title" style={{marginLeft: 5}}>
                How to wash natural dye shibori fabrics or clothes? Is it necessary to use lerak?
                </label>
                <div class="panel-content">Rinse the fabric or clothes without soaking them, and do not dry them under direct sunlight. It is not necessary to use lerak, but do not wash them with soap containing bleach.</div>
              </div>

              <div class="faq-question">
                <input id="q2" type="checkbox" class="panel" />
                <div class="plus">+</div>
                <label for="q2" class="panel-title" style={{marginLeft: 5}}>
                Will shibori clothes fade?
                </label>
                <div class="panel-content">
                The color will reduce during the first wash, but the rinse water will only turn a clear greenish color. After 2-3 washes, this will not happen again.
                </div>
              </div>

              <div class="faq-question">
                <input id="q3" type="checkbox" class="panel" />
                <div class="plus">+</div>
                <label for="q3" class="panel-title" style={{marginLeft: 5}}>
                Will the clothes feel hot when worn?
                </label>
                <div class="panel-content">
                No, because the fabric is made from natural fibers and does not contain polyester, so it remains cool, comfortable, and absorbs sweat well.
                  {/* <a
                    href="https://en.wikipedia.org/wiki/The_Unanswered_Question"
                    target="_blank"
                  >
                    unanswered
                  </a> */}
                </div>
              </div>

              <div class="faq-question">
                <input id="q4" type="checkbox" class="panel" />
                <div class="plus">+</div>
                <label for="q4" class="panel-title" style={{marginLeft: 5}}>
                Is it possible for custom sizes be made? How long does it take to make one shirt?
                </label>
                <div class="panel-content">
                Yes, custom sizes can be made according to your comfortable size. It takes about 3-4 weeks to make one shirt. Please do not hesitate to contact us directly.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
