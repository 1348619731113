import React, { useCallback, useEffect, useState } from "react";
import Footer from "../components/Sections/Footer";
import { useParams } from "react-router-dom";
import "../style/detailnews.css";
import moment from "moment";
import Navigations from "../components/Nav/navigations";

export default function DetailNews() {
  const { id } = useParams();
  const [detail, setdetail] = useState("");
  
  const getContentNewsDetail = useCallback(() => {
    fetch(`https://api.rositabatikshibori.com/api/v1/content/${id}`, {
      method: "GET",
      headers: {
        Authorization:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyLCJ1c2VybmFtZSI6ImFkbWluIiwiaWF0IjoxNzE3NDI4MzI3fQ.brC0Z-uiBEek-dYIpIDjluujyUHWPWxPywqpd_FoaBw",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setdetail(data.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },[id]);

  useEffect(() => {
    getContentNewsDetail();
  }, [getContentNewsDetail]);
  
  return (
    <>
      <Navigations />
      <div className="lightBg">
        <div class="container">
          {/* <h1 style={{ marginBottom: 40 }}>Our News</h1>  */}
          <div class="row">
            <div class="col-lg-8">
              <div class="single-article-section">
                <div class="single-article-text">
                  <div class="single-artcile-bg">
                    <img
                      className=""
                      src={
                        "https://api.rositabatikshibori.com/asset/content/" +
                        detail.content_photo
                      }
                      alt="project"
                      style={{ objectFit: "cover", width: 780, height: 450 }}
                    ></img>
                  </div>
                  <p class="blog-meta">
                    <span class="author">
                      <i class="fas fa-user"></i> Admin,
                    </span>
                    <span class="date">
                      <i class="fas fa-calendar"></i> {moment(detail.date).format('DD MMMM YYYY')}
                    </span>
                  </p>
                  <h2 style={{ marginBottom: 10 }}>{detail.title}</h2>

                  <p>{detail.description}</p>
                </div>

                {/* <div class="comment-template">
                  <h4>Leave a comment</h4>
                  <p>If you have a comment dont feel hesitate to send us your opinion.</p>
                  <form action="index.html">
                    <p>
                      <input type="text" placeholder="Your Name"/>
                      <input type="email" placeholder="Your Email"/>
                    </p>
                    <p><textarea name="comment" id="comment" cols="30" rows="10" placeholder="Your Message"></textarea></p>
                    <p><input type="submit" value="Submit"/></p>
                  </form>
                </div> */}
              </div>
            </div>
            {/* <div class="col-lg-4">
              <div class="sidebar-section">
                <div class="recent-posts">
                  <h4>Recent Posts</h4>
                  <ul>
                    <li><a href="single-news.html">You will vainly look for fruit on it in autumn.</a></li>
                    <li><a href="single-news.html">A man's worth has its season, like tomato.</a></li>
                    <li><a href="single-news.html">Good thoughts bear good fresh juicy fruit.</a></li>
                    <li><a href="single-news.html">Fall in love with the fresh orange</a></li>
                    <li><a href="single-news.html">Why the berries always look delecious</a></li>
                  </ul>
                </div>
                <div class="archive-posts">
                  <h4>Archive Posts</h4>
                  <ul>
                    <li><a href="single-news.html">JAN 2019 (5)</a></li>
                    <li><a href="single-news.html">FEB 2019 (3)</a></li>
                    <li><a href="single-news.html">MAY 2019 (4)</a></li>
                    <li><a href="single-news.html">SEP 2019 (4)</a></li>
                    <li><a href="single-news.html">DEC 2019 (3)</a></li>
                  </ul>
                </div>
                <div class="tag-section">
                  <h4>Tags</h4>
                  <ul>
                    <li><a href="single-news.html">Apple</a></li>
                    <li><a href="single-news.html">Strawberry</a></li>
                    <li><a href="single-news.html">BErry</a></li>
                    <li><a href="single-news.html">Orange</a></li>
                    <li><a href="single-news.html">Lemon</a></li>
                    <li><a href="single-news.html">Banana</a></li>
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
