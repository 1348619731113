import Axios from 'axios'
const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyLCJ1c2VybmFtZSI6ImFkbWluIiwiaWF0IjoxNzE3NDI4MzI3fQ.brC0Z-uiBEek-dYIpIDjluujyUHWPWxPywqpd_FoaBw'
const BASE_URL = `https://api.rositabatikshibori.com/api/v1`


const productWomenRequest = () => {
  return {
    type: 'PRODUCT_WOMEN_REQUEST'
  }
}

const productWomenSuccess = (data) => {
  return {
    type: 'PRODUCT_WOMEN_SUCCESS',
    payload: data
  }
}
const productWomenError = (error) => {
  return {
    type: 'PRODUCT_WOMEN_ERROR',
    payload: error
  }
}


const productWomenSearchRequest = () => {
  return {
    type: 'PRODUCT_WOMEN_SEARCH_REQUEST'
  }
}

const productWomenSearchSuccess = (data) => {
  return {
    type: 'PRODUCT_WOMEN_SEARCH_SUCCESS',
    payload: data
  }
}
const productWomenSearchError = (error) => {
  return {
    type: 'PRODUCT_WOMEN_SEARCH_ERROR',
    payload: error
  }
}


const productMenRequest = () => {
  return {
    type: 'PRODUCT_MEN_REQUEST'
  }
}

const productMenSuccess = (data) => {
  return {
    type: 'PRODUCT_MEN_SUCCESS',
    payload: data
  }
}
const productMenError = (error) => {
  return {
    type: 'PRODUCT_MEN_ERROR',
    payload: error
  }
}


const productAccessoriesRequest = () => {
  return {
    type: 'PRODUCT_ACCESSORIES_REQUEST'
  }
}

const productAccessoriesSuccess = (data) => {
  return {
    type: 'PRODUCT_ACCESSORIES_SUCCESS',
    payload: data
  }
}
const productAccessoriesError = (error) => {
  return {
    type: 'PRODUCT_ACCESSORIES_ERROR',
    payload: error
  }
}


export const GetproductWomen = () => {
  return (dispatch) => {
    dispatch(productWomenRequest())
    return Axios({
      method: 'GET',
      url: `${BASE_URL}/product`,
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const data = res?.data?.data?.data
      dispatch(productWomenSuccess(data))
    }).catch((err) => {
      const message = err.message
      dispatch(productWomenError(message))
    })
  }
}


export const GetproductWomenSearch = (field) => {
  return (dispatch) => {
    dispatch(productWomenSearchRequest())
    return Axios({
      method: 'GET',
      url: `${BASE_URL}/product/search?product_label=${field.product_label}`,
      headers: {
        Authorization: token,
      },
    }).then((res) => {
      const data = res?.data?.data?.data
      dispatch(productWomenSearchSuccess(data))
    }).catch((err) => {
      const message = err.message
      dispatch(productWomenSearchError(message))
    })
  }
}


export const GetproductMen = () => {
  return (dispatch) => {
    dispatch(productMenRequest())
    return Axios({
      method: 'GET',
      url: `${BASE_URL}/product_men`,
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const data = res?.data?.data?.data
      dispatch(productMenSuccess(data))
    }).catch((err) => {
      const message = err.message
      dispatch(productMenError(message))
    })
  }
}


export const GetproductAccessories = () => {
  return (dispatch) => {
    dispatch(productAccessoriesRequest())
    return Axios({
      method: 'GET',
      url: `${BASE_URL}/product_accessories`,
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const data = res?.data?.data?.data
      dispatch(productAccessoriesSuccess(data))
    }).catch((err) => {
      const message = err.message
      dispatch(productAccessoriesError(message))
    })
  }
}


export const searchProduct = (data)=> {
  return{
      type: 'SEARCH_PRODUCT',
      payload: data
  }
}

export const numberWithCommas = (value) => {
  const _value = value + ''
  return _value ? _value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
};