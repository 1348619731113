import React, { useEffect, useMemo } from 'react'
import Footer from '../components/Sections/Footer'
import CardProduct from '../components/CardProduct/cardProduct'
import { useDispatch, useSelector } from 'react-redux'
import { GetproductAccessories, numberWithCommas } from '../redux/actions/product'
import Navigantions from '../components/Nav/navigations';


export default function ProductWomen() {
  const dispatch = useDispatch();

  const { dataAccessories, dataSearch } = useSelector((s) => s.Product);

  useEffect(() => {
    dispatch(GetproductAccessories())
  }, [dispatch])

  const filterSearch = useMemo(() =>
    dataAccessories?.filter((val) => val.product_label === dataSearch?.payload),
    [dataSearch?.payload, dataAccessories])

  return (
    <>
      <Navigantions />
      <div className="container">
        <div className='mt100'>
          <h3 className="font40 extraBold textCenter fontRobotto">Accessories</h3>
          {filterSearch?.length > 0 ? (
            <div className="subcontainer">
              <div className="grid">
                {filterSearch?.map((item, index) => (
                  <CardProduct
                    key={index}
                    image={`https://api.rositabatikshibori.com/asset/productAccessories/${item?.product_photo}`}
                    title={item.product_name}
                    price={numberWithCommas(item.product_price)}
                  />
                ))}
              </div>
            </div>
          ) : (
            <p className='fontEmptyData font20 fontCabin'>Sorry, there are no products in this collection</p>
          )}
        </div>
      </div>
      <Footer />
    </>
  )
}
