import {Contact, DetailNews, FAQ, Landing, News, ProductAccessories, ProductMen, ProductWomen, ReturnRepair, SocialImpact, Sustainability} from './screens' 

export const routes = [
  {
    path: "/",
    element: <Landing/>,
  },
  {
    path: "/product_women",
    element: <ProductWomen />,
  },
  {
    path: "/product_men",
    element: <ProductMen />,
  },
  {
    path: "/product_accessories",
    element: <ProductAccessories />,
  },
  {
    path: "/news",
    element: <News />,
  },
  {
    path: "/detail_news/:id",
    element: <DetailNews />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/social_impact",
    element: <SocialImpact />,
  },
  {
    path: "/sustainability",
    element: <Sustainability />,
  },
  {
    path: "/FAQ",
    element: <FAQ />,
  },
  {
    path: "/ReturnRepair",
    element: <ReturnRepair />,
  },
];

export default routes;