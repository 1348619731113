import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
// Assets
import Accesories from "../../assets/img/accesories.JPG";
import Baju1 from "../../assets/img/baju1.jpg";
import Bajucowo from "../../assets/img/bajucowo.jpeg";
import Batik from "../../assets/img/batik3.JPG";
import videorbs from "../../assets/video/vid-rbs.mp4";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { searchProduct } from "../../redux/actions/product";

export default function Projects() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold" style={{ fontFamily: "Roboto" }}>
              Our Products
            </h1>
            <p
              className="font20 fontCabin lh30 mt20"
              >
              Our products use fabric that made of natural fibers and do not contain polyether, so they are biodegradable, and we also use natural indigo dye. The productions are all hand made, from drawing motifs, cutting the fabric, basting, dyeing, untying, and finishing.
            </p>
          </HeaderInfo>
          <div className="row textCenter font20">
            <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
              <ProjectBox
                img={Baju1}
                title="Shibori for Woman"
                // text="click"
                action={() => {
                  dispatch(searchProduct({ payload: 'Tops and Blouses' }))
                  navigation("/product_women")
                }}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
              <ProjectBox
                img={Bajucowo}
                title="Shibori for Men"
                // text="click"
                action={() => {
                  dispatch(searchProduct({ payload: "Men's Shirts" }))
                  navigation("/product_men")}
                }
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
              <ProjectBox
                img={Accesories}
                title="Accesories"
                // text="Rp 1.000.000"
                action={() => {
                  dispatch(searchProduct({ payload: "Key Chain" }))
                  navigation("/product_accessories")}
                }
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
              <ProjectBox
                img={Batik}
                title="Shawl"
                // text="Rp 1.000.000"
                action={() => navigation("/product_accessories")}
              />
            </div>
          </div>
          {/* <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg4}
                title="Awesome Project"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg5}
                title="Awesome Project"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg6}
                title="Awesome Project"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => alert("clicked")}
              />
            </div>
          </div> */}
          {/* <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton
                title="More Products"
                action={() => alert("clicked")}
              />
            </div>
          </div> */}
        </div>
      </div>

      {/* <div className="video-container">
        <video
          width="100%"
          height="auto"
          controls
          autoPlay={true}
          playsInline={true}
          loop={true}
        >
          <source src={videorbs} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div> */}
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  {/* <img className="radius8" src={AddImage2} alt="add" /> */}
                  <div className="video-container">
                    <video
                      width="100%"
                      height="auto"
                      controls
                      autoPlay={true}
                      playsInline={true}
                      loop={true}
                    >
                      <source src={videorbs} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4
                className="font25 extraBold fontRobotto"
              >
                CHOOSE WELL, MAKE IT LAST
              </h4>
              {/* <h2 className="font40 extraBold">A Study of Creativity</h2> */}
              <p
                className="font20 fontCabin lh30 mt20"
              >
                fashion is part of our lives and an expression of what has an impact on those around us. The designs we create always have modern traditional characteristics so that the aesthetics and beauty of the modern traditional visual appearance is inherent and becomes a characteristic of our products
              </p>
              {/* <ButtonsRow
                className="flexNullCenter"
                style={{ margin: "30px 0" }}
              >
                <div style={{ width: "190px" }}>
                  <FullButton
                    title="Get Started"
                    action={() => alert("clicked")}
                  />
                </div> */}
              {/* <div style={{ width: "190px", marginLeft: "15px" }}>
                  <FullButton
                    title="Contact Us"
                    action={() => alert("clicked")}
                    border
                  />
                </div> */}
              {/* </ButtonsRow> */}
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  margin-top: 10%;
`;
const HeaderInfo = styled.div`
  line-height: 1.5;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 100%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 100%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      // margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 70%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -160px;
  left: -100px;
  @media (max-width: 1190px) {
    top: -130px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 8%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
