import React from 'react'


export default function CardProduct({ image, title, price }) {
    return (
      <div className="card">
        <img src={image} alt={title} className="card-img" />
        <div className="card-body">
          <h3 className="card-title">{title}</h3>
          <p className="card-price">{price}</p>
        </div>
      </div>
    );
  };