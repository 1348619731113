import React, { useEffect, useState } from "react";
import styled from "styled-components";
// Components
import { Link } from "react-router-dom";
import BlogBox from "../Elements/BlogBox";

const Blog = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    getContentNews();
  }, []);
  const getContentNews = () => {
    fetch("https://api.rositabatikshibori.com/api/v1/content", {
      method: "GET",
      headers: {
        Authorization:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyLCJ1c2VybmFtZSI6ImFkbWluIiwiaWF0IjoxNzE3NDI4MzI3fQ.brC0Z-uiBEek-dYIpIDjluujyUHWPWxPywqpd_FoaBw",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setNews(data.data.data.slice(0, 4));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
          <h3 className="font40 extraBold marginTitle textCenter fontRobotto">Our News Stories</h3>
            {/* <p className="font13">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p> */}
          </HeaderInfo>
          <div className="row textCenter">
            {news.map((val, i) => {
              return (
                <div key={i} className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                  <Link to={`/detail_news/${val.content_id}`}>
                    <BlogBox
                      key={val.content_id}
                      title={val.title}
                      photo={
                        "https://api.rositabatikshibori.com/asset/content/" +
                        val.content_photo
                      }
                      text={val.description.substring(0, 60) + "..."}
                      // tag="company"
                      author="Luke Skywalker, 2 days ago"
                      // action={() =>
                      //   (window.location.href = `/detail_news/${val.content_id}`)
                      // }
                    />
                  </Link>
                </div>
              );
            })}
            {/* <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="KAWFEST 2024"
                photo={News2}
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                // tag="company"
                author="Sarinah Thamrin, 2 days ago"
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Kunjungan Batik Shibori"
                photo={News3}
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                // tag="company"
                author="Luke Skywalker, 2 days ago"
                action={() => alert("clicked")}
              />
            </div> */}
          </div>
          {/* <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="New Office!"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                tag="company"
                author="Luke Skywalker, 2 days ago"
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="New Office!"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                tag="company"
                author="Luke Skywalker, 2 days ago"
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="New Office!"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                tag="company"
                author="Luke Skywalker, 2 days ago"
                action={() => alert("clicked")}
              />
            </div>
          </div> */}
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              {/* <FullButton title="Load More" action={() => alert("clicked")} /> */}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="lightBg" style={{padding: '50px 0'}}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">What They Say?</h1>
            <p className="font13">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div> */}
    </Wrapper>
  );
};

export default Blog;

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
